// @keyframes bounceHorizontalAnimation {
//   10% {
//     transform: translateX(5%);
//   }
//   45% {
//     transform: translateX(10%);
//   }
//   65% {
//     transform: translateX(15);
//   }
//   75% {
//     transform: translateX(20%);
//   }
//   85% {
//     transform: translateX(15%);
//   }
//   100% {
//     transform: translateX(0%);
//   }
// }

@keyframes bounceHorizontalAnimation {
  1% {
    transform: translateX(5%);
  }
  2% {
    transform: translateX(10%);
  }
  3% {
    transform: translateX(15);
  }
  4% {
    transform: translateX(20%);
  }
  5% {
    transform: translateX(0%);
  }
  6% {
    transform: translateX(5%);
  }
  7% {
    transform: translateX(10%);
  }
  8% {
    transform: translateX(15);
  }
  9% {
    transform: translateX(20%);
  }
  10% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
  }
}

.bounceHorizontalStyle {
  animation-delay: 0s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: bounceHorizontalAnimation;
}

.closeButton {
  color: #00000099;
  &:hover {
    color: black;
  }
}

.noStyle {
  margin-left: 0;
}

.moreInfoHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  width: 98%;
}

.seamore {
  cursor: pointer;
}
