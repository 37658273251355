.footer {
  align-items: center;
  background-color: #555555;
  border-top: 1px solid black;
  bottom: 0;
  display: flex;
  height: 24px;
  justify-content: center;
  padding-top: 3px;
  position: absolute;
  width: 100%;
}

.pageContent {
  display: flex;
  flex: 1;
  flex-direction: row;
}
