.cardActionContainer {
  display: flex;
  flex-direction: column;
}

.cardActions {
  justify-content: center;
}

/*.cards {
  min-width: 350px;
  width: 350px;
}*/

.cardTitle {
  display: flex;
  flex-direction: row;
}

.characterChoiceList {
  color: black;
  height: 30px;
  margin: 10px -55px 0 0;
  width: 160px;
}

.characterChoiceListMobile {
  composes: characterChoiceList;
  margin: 10px -15px 0 0;
}

.closeButton {
  color: #00000099;
  &:hover {
    color: black;
  }
}

.description {
  //margin: 25px auto 0 auto;
  text-align: center;
}

.filterChoiceList {
  color: black;
  height: 30px;
  margin: 10px 10px 0 auto;
  width: 140px;
}

.filterChoiceListMobile {
  composes: filterChoiceList;
  width: 120px;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.keywordChoiceList {
  color: black;
  height: 30px;
  margin: 10px -60px 0 0;
  width: 250px;
}

.keywordChoiceListMobile {
  composes: keywordChoiceList;
  margin: 10px 5px 0 0;
  width: 190px;
}

.menuIcon {
  color: #1976d2; // #888888;
  cursor: pointer;
  margin: -10px -7px 0 auto;
  &:hover {
    color: #1e8fff;
  }
}

.moreInfoHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.noShowsMessage {
  margin-top: 40px;
}

.pageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  width: 98%;
}

.searchIcon {
  align-self: flex-end;
  cursor: pointer;
  display: flex;
  height: 30px;
  margin: 0 30px 0 auto;
  width: 30px;
}

.searchIconMobile {
  composes: searchIcon;
  margin: 0 4px 0 auto;
}

.stickyHeader {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: sticky;
  top: 0;
}

.tabs {
  align-self: flex-start;
  margin: 20px 7px;
}
