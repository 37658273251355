.moreInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-items: stretch;
}

.moreInfoContainer {
  display:flex;
  flex-direction: column;
  overflow-y: scroll;
}


