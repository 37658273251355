.menuIcon {
  color: white;
}

.mobileMenuBar {
  background-color: #555555;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 49px;
}

.mobileMenuBarText {
  margin: auto 0;
}

.tabs {
  height: 40px;
  justify-self: center;
  margin-top: 20px;
}
